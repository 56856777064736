<template>
    <v-form v-model="isValid">
        <vertical-form-table v-model="form" v-bind="{ items }" @input="emit">
            <template #foot>
                <div class="btn-wrap btn-wrap--lg">
                    <v-row justify="center" class="row--sm">
                        <v-col cols="6" sm="auto">
                            <v-btn v-bind="{ ...btn_primary, ...attrs, loading, disabled }" class="v-size--xx-large w-100 min-w-sm-200px" @click="$emit('save')">신청하기</v-btn>
                        </v-col>
                    </v-row>
                </div>
            </template>
        </vertical-form-table>
    </v-form>
</template>

<script>
import { attrs_input, btn_primary, initForm, rules } from "@/assets/variables";

import VerticalFormTable from "@/components/dumb/vertical-form-table.vue";

const items = [
    {
        key: "writer.name",
        term: "이름",
        type: "text",
        rules: rules.name,
        required: true,
        ...attrs_input,
    },
    {
        key: "writer.phone",
        term: "연락처",
        type: "phone",
        rules: rules.phone,
        required: true,
        ...attrs_input,
    },
    {
        key: "writer.email",
        term: "이메일",
        type: "email",
        rules: rules.email,
        required: true,
        ...attrs_input,
    },
    {
        key: "subject",
        term: "문의제목",
        type: "text",
        rules: rules.subject,
        required: true,
        ...attrs_input,
    },
    {
        key: "content",
        term: "문의내용",
        type: "textarea",
        rules: rules.content,
        required: true,
        ...attrs_input,
    },
];

export default {
    components: {
        VerticalFormTable,
    },
    props: {
        value: { type: Object, default: initForm },
        loading: { type: Boolean, default: false },
    },
    data: () => ({
        form: initForm(),

        items,
        btn_primary,
        isValid: false,
    }),
    computed: {
        disabled() {
            return !this.isValid;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        value() {
            this.sync();
        },
    },
    methods: {
        sync() {
            this.form = initForm(this.value);
        },
        emit() {
            this.$emit("input", this.form);
        },
    },
};
</script>
